"use client"
import {Button, Chip, Container, FormControl, Grid, Theme, ThemeProvider, Typography} from '@material-ui/core'
import {graphql, Link}                                                                from 'gatsby'
import React, {useState}                                                              from 'react'
import BarristerList                                                                  from '../components/barristerList'
import {
    FadeAndSlide
}                                                                                     from '../components/animations/FadeSlideAnimations'
import Layout                                                                         from '../components/shared/layout'
import theme                                                                          from '../theme'
import {makeStyles}                                                                   from '@material-ui/core/styles'
import useBarristersInOrder, {
    BarristerOrder
}                                                                                     from '../hooks/useBarristersInOrder'
import {Barrister}                                                                    from '../../entities/barrister'
import {practiceAreaConstants}                                                        from '../practice-areas'
import InputLabel
                                                                                      from '@material-ui/core/InputLabel'
import Select                                                                         from '@material-ui/core/Select'
import MenuItem                                                                       from '@material-ui/core/MenuItem'


export const query = graphql`
    query {
        placeholderImage: sanitySiteSettings {
            siteMedia {
                peoplePlaceholder {
                    ...ImageData
                }
            }
        }
        barristerData: allSanityBarrister(sort: { order: ASC, fields: lastName }) {
            edges {
                node {
                    _id
                    preferredName
                    lastName
                    firstName
                    barristerGroup
                    slug {
                        current
                    }
                    barristerPortrait {
                        ...PortraitData
                    }
                    practices {
                        admissionToPracticeYear
                        silkYear
                        takenSilk
                        joinedBarYear
                    }
                    practiceAreas {
                        topLevelPracticeArea
                        commercialLaw {
                            subPracticeAreas
                        }
                        criminalLaw {
                            subPracticeAreas
                        }
                        employmentIndustrial {
                            subPracticeAreas
                        }
                        intellectualProperty {
                            subPracticeAreas
                        }
                        publicLaw {
                            subPracticeAreas
                        }
                        tortLaw {
                            subPracticeAreas
                        }
                    }
                }
            }
        }
    }
`

const useStyles = makeStyles((theme: Theme) => ({
    pageTitle: {
        color: '#FFF',
        fontWeight: 500,
        margin: '0.5rem 0 0.7rem',
        textAlign: 'center'
    },
    barristerNameChip: {
        color: '#FFF',
        borderRadius: 0,
        borderColor: 'goldenrod',
        fontSize: '1.2rem',
        '&:hover': {
            background: '#FFF',
            color: 'goldenrod',
            cursor: 'pointer'
        }
    },
    sortWrapper: {
        // margin: '2em 0em 2em 0'
    },
    sortContainer: {
        // textAlign: 'center'
    },
    sortButton: {
        color: 'goldenrod',
        display: 'inline-block'
    },
    filterButton: {
        transition: '0.5s',
        color: 'goldenrod',
        display: 'inline-block',
        marginBottom: '1rem',
        '&:hover': {
            background: 'goldenrod',
            color: '#FFF'
        }
    },
    activeFilterButton: {
        transition: '0.5s',
        background: 'goldenrod',
        color: '#FFF',
        marginBottom: '1rem',
        '&:hover': {
            background: '#FFF',
            color: '#000'
        }
    },
    showAllButton: {
        transition: '0.5s',
        color: 'goldenrod',
        display: 'inline-block',
        background: '#FFF',
        marginBottom: '1rem',
        '&:hover': {
            color: '#000',
            background: '#FFF'
        }
    },
    noStyleLink: {
        textDecoration: 'none'
    },
    groupsLabel: {
        color: '#FFF'
    },
    orderBarristerLabel: {
        color: '#FFF',
        fontSize: '1rem'
    },
    barristerOrderControl: {
        margin: theme.spacing(1)
    },
    selectRootStyles: {
        color: 'goldenrod',
        '&:before': {
            borderBottomColor: '#FFF'
        },
        '&:before .MuiSelectRoot': {
            color: '#FFF' // Text color
        },
        '& .MuiInput-underline::before': {
            borderBottomColor: '#fff8' // Semi-transparent underline
        },
        '&:before .MuiInput-underline:hover': {
            borderBottomColor: '#FFF' // Solid underline on hover
        },
        '&:before .MuiInput-underline': {
            borderBottomColor: '#FFF' // Solid underline on focus
        }
    },
    selectUnderlineStyles: {
        '&:before': {
            borderBottom: '2px solid green'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid blue'
        },
        '&:after': {
            borderBottom: '3px solid purple'
        }
    },
    switchThumb: {
        color: 'goldenrod'
    }
}))

type BarristerQuery = {
    barristerData: { edges: { node: Barrister }[] },
    barristerGroups: any
}

interface BarristerPageProps {
    areas: any
    data: BarristerQuery
    barristers: any[]
    scBarristers: any[]
    nameSearch: string
    theme: Theme
}

type BarristerSortCriteria = 'seniority' | 'lastName'

type BarristerCollections = {
    barristers: Barrister[] //
    barristersBySeniority: [] //

}

const BarristerPage = (props: BarristerPageProps) => {
    const classes = useStyles(theme)
    const {data} = props

    // STATE ===
    const [orderBarristersBy, setOrderBarristersBy] = useState<BarristerOrder>('lastName')
    const [practiceAreaFilter, setPracticeAreaFilter] = useState<string | undefined>('')

    const allBarristers = useBarristersInOrder(data.barristerData, orderBarristersBy, practiceAreaFilter)


    const handleChangeBarristerOrder = (event: { value: BarristerSortCriteria }) => {
        setOrderBarristersBy(event.value)
    }

    const handleFilterByPracticeArea: (practiceAreaId: string) => void = (practiceAreaId) => {
        setPracticeAreaFilter(practiceAreaId)
    }

    const renderBarristerNames = (barristers: Barrister[] | undefined) => (
        <Container maxWidth={'lg'}>
            <Grid container spacing={2} justify={'space-around'}>
                {
                    barristers && barristers.map((b, idx) =>
                                   <Grid key={idx} item>
                                       <Link className={classes.noStyleLink} to={`/barristers/${b.slug.current}`}>
                                           <Chip size={'medium'} className={classes.barristerNameChip} variant={'outlined'}
                                                 label={b.preferredName}/>
                                       </Link>
                                   </Grid>
                               )
                }
            </Grid>
        </Container>
    )

    const renderSortButton = () => (
        <Container maxWidth={'lg'}>
            <Grid container className={classes.sortWrapper} spacing={4} alignContent={'center'} alignItems={'center'}
                  justify={'center'}>
                {/*
                <Grid container justify={'center'} item xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                classes={{thumb: classes.switchThumb}}
                                checked={showGroups}
                                onChange={() => setShowGroups(!showGroups)}
                                name={'showGroups'}
                                inputProps={{
                                    'aria-label': 'show barristers in groups'
                                }}
                            />
                        }
                        label={<span className={classes.groupsLabel}>Show Groups</span>}
                        labelPlacement={'start'} />
                </Grid>
*/}
                <Grid container alignItems={'center'} item sm={12} md={6}>
                    <FormControl size={'small'} fullWidth={true} variant={'filled'}
                                 className={classes.barristerOrderControl}>
                        <InputLabel style={{color: '#FFF'}} id="filter-barristers-label">Filter by Practice
                            Area</InputLabel>
                        <Select
                            classes={{
                                root: classes.selectRootStyles,
                                icon: classes.selectRootStyles
                            }}
                            labelId="filter-barristers-label"
                            id="demo-controlled-open-select"
                            value={practiceAreaFilter}
                            onChange={(event) => handleFilterByPracticeArea(event.target.value)}
                        >
                            <MenuItem value={''}>Show All Barristers</MenuItem>
                            {practiceAreaConstants &&
                             practiceAreaConstants.map((pa, idx) => (
                                 <MenuItem key={idx} value={pa.id}>
                                     {pa.title}
                                 </MenuItem>
                             ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container alignItems={'center'} item sm={12} md={3}>
                    <FormControl size={'small'} fullWidth={true} variant={'filled'}
                                 className={classes.barristerOrderControl}>
                        <InputLabel style={{color: '#FFF'}} id="sort-barristers-label">Sort by</InputLabel>
                        <Select
                            classes={{
                                root: classes.selectRootStyles,
                                icon: classes.selectRootStyles
                            }}
                            labelId="sort-barristers-label"
                            id="demo-controlled-open-select"
                            value={orderBarristersBy}
                            onChange={(event) => handleChangeBarristerOrder(event.target)}
                        >
                            <MenuItem value={'lastName'}>Last Name</MenuItem>
                            <MenuItem value={'seniority'}>Seniority</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    )

    const renderFilterButtons = () => (
        <Container maxWidth={'lg'}>
            <Grid container>
                <Grid className={classes.sortContainer} item xs={12}>
                    <Grid container justify={'space-between'} alignItems={'center'} spacing={1}>
                        {practiceAreaConstants &&
                         practiceAreaConstants.map((pa, idx) => (
                             <Grid item xs={4} key={idx}>
                                 <Button fullWidth
                                         onClick={() => handleFilterByPracticeArea(pa.id)}
                                         className={practiceAreaFilter === pa.id ? classes.activeFilterButton : classes.filterButton}
                                 >
                                     {pa.title}
                                 </Button>
                             </Grid>
                         ))}
                    </Grid>

                    {
                        practiceAreaFilter && (
                                               <Grid container justify={'center'} item xs={12}>
                                                   <Button
                                                       onClick={() => handleFilterByPracticeArea('')}
                                                       className={classes.showAllButton}
                                                   >
                                                       Show All Barristers
                                                   </Button>
                                               </Grid>
                                           )
                    }
                    {/*
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showPhotos}
                                    onChange={() => setShowPhotos(!showPhotos)}
                                    name={'showPhotos'}
                                    inputProps={{
                                        'aria-label': 'toggle display of photographs of barrister'
                                    }}
                                />
                            }
                            label={<span className={classes.groupsLabel}>Show Photographs</span>}
                            labelPlacement={'start'} />
                    </Grid>
*/}
                </Grid>
            </Grid>
        </Container>
    )

    const renderBarristers = (barristers: Barrister[] | undefined) => {
        return barristers ?
               (
                   <BarristerList displayMode={'cards-only'}
                                  barristers={barristers}/>
               ) : null
    }

    return (
        <ThemeProvider theme={theme}>
            <Layout helmetData={{
                pageTitle: 'Barristers',
                metaDescription: 'LEVEL 22 CHAMBERS is a leading commercial and administrative set based in the heart of Sydney’s legal precinct',
                slug: 'barristers'
            }} backgroundImage={''}>
                <Container maxWidth={'lg'}>
                    <FadeAndSlide direction={'down'}>
                        <Typography style={{color: '#FFF'}} variant={'h1'} component={'h1'}>
                            Barristers
                        </Typography>
                    </FadeAndSlide>

                </Container>
                <FadeAndSlide direction={'fade'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                allBarristers ? renderSortButton() : null
                            }
                        </Grid>
                    </Grid>
                </FadeAndSlide>
                <FadeAndSlide direction="fade" duration={1000}>
                    {
                        allBarristers && renderBarristers(allBarristers.filter((b) => b.barristerGroup !== 'academicMember'))
                    }
                </FadeAndSlide>
                <Container style={{marginTop: '2rem', marginBottom: '2rem'}} maxWidth={'lg'}>
                    <FadeAndSlide direction={'down'}>
                        <Typography style={{color: '#FFF'}} variant={'h1'} component={'h1'}>
                            Academic Members
                        </Typography>
                    </FadeAndSlide>
                </Container>
                <FadeAndSlide direction="fade" duration={1000}>
                    {
                        allBarristers && renderBarristers(allBarristers.filter((b) => b.barristerGroup === 'academicMember'))
                    }
                </FadeAndSlide>
            </Layout>
        </ThemeProvider>
    )
}

export default BarristerPage
